<template>
  <div class="index-container no-fullpage">
    <common-header
        :is-show-menu="true"
        :client-width="clientWidth"
        currentMenu="plan"
        @openMobileMenu="openMobileMenu"
    />
    <!-- <full-page ref="fullpage" :options="options" id="fullpage"> -->
    <div class="section section1">
      <introduction :clientWidth="clientWidth"/>
    </div>
    <div class="section section2" ref="introduce">
      <product-introduce :clientWidth="clientWidth" @toApply="handleToApply"/>
    </div>
    <div class="section section3" ref="chara">
      <product-chara :clientWidth="clientWidth" @toApply="handleToApply"/>
    </div>
    <div class="section section4" ref="adapt">
      <adaptation-product :clientWidth="clientWidth" @toApply="handleToApply"/>
    </div>
    <div class="section fp-auto-height">
      <common-footer :is-index="true" v-show="clientWidth>mobileWidth"/>
      <mobile-footer v-show="clientWidth<=mobileWidth"/>
    </div>
    <!-- </full-page> -->
    <slide-menu ref="slideMenu"/>
    <apply-dialog ref="applyDialog" :clientWidth="clientWidth"/>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
import ApplyDialog from '@components/ApplyDialog.vue';
import Introduction from './components/TitleIntroduction.vue';
import ProductIntroduce from './components/ProductIntroduce.vue';
import ProductChara from './components/ProductChara.vue';
import AdaptationProduct from './components/AdaptationProduct.vue';
import SlideMenu from '../../components/Menu.vue';

export default {
  name: 'LocalizationPlan',
  components: {
    CommonHeader,
    CommonFooter,
    MobileFooter,
    SlideMenu,
    Introduction,
    ProductIntroduce,
    ProductChara,
    AdaptationProduct,
    ApplyDialog,
  },
  data() {
    return {
      clientWidth: 1920,
      options: {
        // licenseKey: 'YOUR_KEY_HEERE',
        paddingTop: '76px',
        slidesNavigation: true,
        anchors: ['title', 'introduce', 'chara', 'adapt'],
        lockAnchors: true,
      },
    };
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  mounted() {
    this.handleResize();
    this.handleScroll();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    if (this.$route.query.anchor) {
      this.jumpSection(this.$route.query.anchor);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
      this.options.paddingTop = '50px';
    },
    openMobileMenu() {
      this.$refs.slideMenu.onOpen();
    },
    jumpSection(section) {
      // this.$refs.fullpage.api.moveTo(section, 0);

      const anchorEle = this.$refs[`${section}`];
      if (anchorEle) {
        this.$nextTick(() => {
          anchorEle.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        });
      }
    },
    handleToApply() {
      this.$refs.applyDialog.openDialog();
    },
    handleScroll() {
      const scrollTop = window.pageYOffset
          || document.documentElement.scrollTop || document.body.scrollTop;
      console.log(scrollTop);
      const element1 = document.querySelector('.section1');
      const element2 = document.querySelector('.section2');
      const element3 = document.querySelector('.section3');

      if (this.clientWidth <= this.mobileWidth) {
        if (scrollTop >= 0 && scrollTop < 400) {
          element1.classList.add('active');
        }
        if (scrollTop >= 400 && scrollTop < 1000) {
          element2.classList.add('active');
        }
        if (scrollTop >= 1000 && scrollTop < 1700) {
          element3.classList.add('active');
        }
      } else {
        if (scrollTop >= 0 && scrollTop < 500) {
          element1.classList.add('active');
        }
        if (scrollTop >= 500 && scrollTop < 1380) {
          element2.classList.add('active');
        }
        if (scrollTop >= 1380 && scrollTop < 2100) {
          element3.classList.add('active');
        }
      }
    },
  },
};

</script>

<style lang="less" scoped>
@import "../../static/fullpage.min.css";
@import "../../less/mobileIndex.less";

.fp-auto-height {
  padding: 0 !important;
}

.section1.active {
  /deep/ .introduct-content {
    .sys-title {
      animation: zoomIn 1s 1;
    }

    .desc {
      animation: fadeIn 1s 1;
    }
  }
}

.section2.active {
  /deep/ .product-content, /deep/ .mobile-product-content {
    overflow: hidden;

    .title {
      animation: zoomIn 1s 1;
    }

    .peitu {
      animation: fadeInRight 2s 1;
      opacity: 1;
    }
  }
}

.section3.active {
  /deep/ .characteristic-content {
    overflow: hidden;

    .title {
      animation: rotateIn 1s 1;

      &::after {
        transition-delay: 1s;
        animation-delay: 1s;
        width: 120px;
        animation-name: example;
        animation-duration: 4s;
      }
    }
  }

  /deep/ .mobile-characteristic-content {
    .title {
      &::after {
        width: 60px;
      }
    }
  }
}

@keyframes example {
  from {
    width: 0
  }
  to {
    width: 120px
  }
}

.no-fullpage {
  .section {
    height: 100vh;
    min-height: 700px;
  }

  .fp-auto-height {
    height: auto;
    min-height: 0;
  }
}
</style>
