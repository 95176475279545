<template>
  <div :class="[
    'characteristic-content',
    {'mobile-characteristic-content':clientWidth<=mobileWidth}
  ]">
    <div class="title">产品特点</div>

    <div class="my-swiper" v-if="clientWidth>mobileWidth">
      <swiper
          class="my-swiper-container"
          :options="swiperOption"
      >
        <swiper-slide v-for="(item,index) in swiperData" :key="index">
          <div class="swiper-item-content">
            <div class="img-wapper">
              <img :src="require(`../../../assets/imgs/localizationPlan/icon_${item.imgName}@2x.png`)">
            </div>
            <p class="title-word">{{ item.title }}</p>
            <p class="desc-word">{{ item.desc }}</p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev" v-if="clientWidth>mobileWidth">
        <img src="../../../assets/imgs/icon_left@2x.png">
      </div>
      <div class="swiper-button-next" slot="button-next" v-if="clientWidth>mobileWidth">
        <img src="../../../assets/imgs/icon_right@2x.png">
      </div>
    </div>
    <div class="chara-wapper" v-else>
      <div class="chara-item" v-for="(item,index) in swiperData" :key="index">
        <div class="img-wapper">
          <img
              :src="require(`../../../assets/imgs/localizationPlan/icon_${item.imgName}@2x.png`)">
        </div>
        <div>
          <p class="title-word">{{ item.title }}</p>
          <p class="desc-word">{{ item.desc }}</p>
        </div>
      </div>
    </div>
    <div class="btn" @click="toApply">申请试用</div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'characteristic',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 80,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        allowTouchMove: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperData: [
        {
          title: '可信安全',
          desc: '广烁电子签章（国产化）平台采用SM2、SM3国密算法，系统设计符合国家标准，可信安全。',
          imgName: 'kexin',
        },
        {
          title: '标准兼容',
          desc: '满足安全电子签章密码技术规范，兼容全类型OFD版式阅读器。',
          imgName: 'jianrong',
        },
        {
          title: '集成快捷',
          desc: '一套控件，一次集成，适用多种应用环境。',
          imgName: 'jicheng',
        },
        {
          title: '自主创新',
          desc: '核心技术自主研发，全方位保护签署安全。',
          imgName: 'chuangxin',
        },
        {
          title: '互验互通',
          desc: '支持各国产化关键件，支持多平台混合部署，支持签署文件互验互通。',
          imgName: 'hutong',
        },
      ],
    };
  },
  methods: {
    toApply() {
      this.$emit('toApply');
    },
  },
};
</script>

<style lang="less" scoped>
.characteristic-content {
  background: #F5F5F5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    position: relative;
    text-align: center;
    margin-bottom: 104px;

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 8px;
      background: #F75959;
      border-radius: 4px;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .my-swiper {
    height: 300px;
    position: relative;

    .my-swiper-container {
      width: 70%;
      margin: 0 auto;
    }

    .swiper-item-content {
      width: 228px;
      height: 280px;
      text-align: center;

      .img-wapper {
        width: 97px;
        height: 96px;
        margin: 0 auto 22px;

        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          user-select: none;
        }
      }

      .title-word {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        user-select: none;
      }

      .desc-word {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        user-select: none;
      }
    }

    .swiper-button-prev,.swiper-button-next{
      top: 40%;
      img{
        width: 44px;
        height: 44px;
      }
      &::after{
        display: none;
      }
    }
    .swiper-button-prev{
      left: 100px;
    }
    .swiper-button-next{
      right: 100px;
    }
  }

  .btn {
    width: 200px;
    margin: 32px auto;
    background: #F75959;
    border-radius: 30px;
    padding: 15px 0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 21px;
  }

  .swiper-pagination-progressbar {
    width: 60px;
    height: 8px;
    background: #ddd;
    border-radius: 4px;
    bottom: 0;
    left: 50%;
    top: auto;
    transform: translateX(-50%);
  }

  ::v-deep.swiper-pagination-progressbar-fill {
    background: #F75959;
    border-radius: 10px;
  }
}

@media (max-width: 760px) {
  .mobile-characteristic-content {
    .title {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      margin-bottom: 16px;

      &::after {
        // width: 0px;
        height: 4px;
        bottom: -5px;
      }
    }

    .chara-wapper {
      background: #fff;
      padding: 30px 25px;
      width: 300px;
      margin: 0 auto;

      .chara-item {
        display: flex;

        .img-wapper {
          width: 48px;
          height: 47px;
          margin-right: 20px;

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }

        .title-word {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
        }

        .desc-word {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.45);
          line-height: 17px;
          width: 188px;
        }
      }
    }

    .btn {
      width: 128px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      padding: 0;
    }
  }
}
</style>
