<template>
  <div :class="['adaptation-product',{'mobile-adapt-product':clientWidth<=mobileWidth}]">
    <div class="title">适配产品</div>
    <div class="img-wapper">
      <img v-if="clientWidth>mobileWidth"
      src="../../../assets/imgs/localizationPlan/pic_shipeichanpin@2x.png">
      <img src="../../../assets/imgs/mobile/pic_shipeichanpin@2x.png" v-else>
    </div>
    <div class="btn" @click="toApply">申请试用</div>
  </div>
</template>

<script>
export default {
  name: 'AdaptationProduct',
  data() {
    return {
    };
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toApply() {
      this.$emit('toApply');
    },
  },
};
</script>

<style lang="less" scoped>
  .adaptation-product{
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title{
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      position: relative;
      text-align: center;
      margin-bottom: 80px;
      &::after{
        position: absolute;
        content: '';
        width: 120px;
        height: 8px;
        background: #F75959;
        border-radius: 4px;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .img-wapper{
      height: 421px;
      width: 1200px;
      margin: 0 auto 80px;
      img{
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .btn{
      width: 200px;
      margin: 0 auto;
      background: #F75959;
      border-radius: 30px;
      padding: 15px 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-size: 21px;
    }
  }
  @media(max-width:760px){
    .mobile-adapt-product{
      .title{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
        margin-bottom: 16px;
        &::after{
          width: 60px;
          height: 4px;
          bottom: -5px;
        }
      }
      .img-wapper{
        text-align: center;
        width: 100%;
        height: 397px;
        margin-bottom: 15px;
      }
      .btn{
        width: 128px;
        height: 36px;
        background: #F75959;
        border-radius: 23px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 36px;
        margin: 0 auto;
        padding: 0;
      }
    }
  }
</style>
