<template>
  <div :class="clientWidth>mobileWidth?'product-content':'mobile-product-content'">
    <div class="left-content">
      <div class="mobile-title">
        <img src="../../../assets/imgs/localizationPlan/icon_jieshao@2x.png"/>
        <p class="title">产品介绍</p>
      </div>
      <p class="desc">
        广烁电子签章（国产化）平台，是基于对国家网络安全和信息化发展战略的深刻理解，打造出的符合信创产品要求的电子签章应用解决方案，全方位支持各类国产化产品使用需求。
      </p>
      <div class="btn" v-if="clientWidth>mobileWidth" @click="toApply">申请试用</div>
    </div>
    <img src="../../../assets/imgs/localizationPlan/pic_jieshao@2x.png" class="peitu"/>
    <div class="btn" v-if="clientWidth<=mobileWidth" @click="toApply">申请试用</div>
  </div>
</template>

<script>
export default {
  name: 'ProductIntroduce',
  data() {
    return {};
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toHome() {
      this.$router.push('./eseal');
    },
    toApply() {
      this.$emit('toApply');
    },
  },
};

</script>

<style lang="less" scoped>
.product-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #fff;

  .left-content {
    margin-right: 98px;

    img {
      width: 44px;
      height: 47px;
    }

    .title {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin: 31px 0 16px;
    }

    .desc {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 30px;
      width: 352px;
    }

    .btn {
      width: 200px;
      background: #F75959;
      border-radius: 30px;
      padding: 15px 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-size: 21px;
    }
  }

  .peitu {
    width: 643px;
    height: 650px;
    display: block;
    opacity: 0;
  }
}

.mobile-product-content {
  background: #FFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .mobile-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    img {
      width: 16px;
      height: 15px;
    }

    p {
      margin: 0 0 0 8px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
  }

  .desc {
    padding: 0 50px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.45);
    line-height: 17px;
    text-align: center;
  }

  .peitu {
    width: 243px;
    height: 246px;
    display: block;
    margin: 40px auto 0;
    opacity: 0;
  }

  .btn {
    width: 128px;
    height: 36px;
    background: #F75959;
    border-radius: 23px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    margin: 60px auto 0;
  }
}
</style>
